export const TOP_CITIES = [
    {
        city: 'Atlanta',
        url: 'atlanta-ga',
        state: 'GA',
    },
    {
        city: 'Austin',
        url: 'austin-tx',
        state: 'TX',
    },
    {
        city: 'Boston',
        url: 'boston-ma',
        state: 'MA',
    },
    {
        city: 'Calgary',
        url: 'calgary-ab',
        state: 'AB',
    },
    {
        city: 'Chicago',
        url: 'chicago-il',
        state: 'IL',
    },
    {
        city: 'Columbus',
        url: 'columbus-oh',
        state: 'OH',
    },
    {
        city: 'Dallas',
        url: 'dallas-tx',
        state: 'TX',
    },
    {
        city: 'Denver',
        url: 'denver-co',
        state: 'CO',
    },
    {
        city: 'Edmonton',
        url: 'edmonton-ab',
        state: 'AB',
    },
    {
        city: 'Houston',
        url: 'houston-tx',
        state: 'TX',
    },
    {
        city: 'Las Vegas',
        url: 'las-vegas-nv',
        state: 'NV',
    },
    {
        city: 'Logan',
        url: 'logan-ut',
        state: 'UT',
    },
    {
        city: 'Los Angeles',
        url: 'los-angeles-ca',
        state: 'CA',
    },
    {
        city: 'Milwaukee',
        url: 'milwaukee-wi',
        state: 'WI',
    },
    {
        city: 'Minneapolis',
        url: 'minneapolis-mn',
        state: 'MN',
    },
    {
        city: 'Nashville',
        url: 'nashville-tn',
        state: 'TN',
    },
    {
        city: 'New York',
        url: 'new-york-ny',
        state: 'NY',
    },
    {
        city: 'Old Toronto',
        url: 'old-toronto-on',
        state: 'ON',
    },
    {
        city: 'Omaha',
        url: 'omaha-ne',
        state: 'NE',
    },
    {
        city: 'Ottawa',
        url: 'ottawa-on',
        state: 'ON',
    },
    {
        city: 'Philadelphia',
        url: 'philadelphia-pa',
        state: 'PA',
    },
    {
        city: 'Phoenix',
        url: 'phoenix-az',
        state: 'AZ',
    },
    {
        city: 'Portland',
        url: 'portland-or',
        state: 'OR',
    },
    {
        city: 'San Antonio',
        url: 'san-antonio-tx',
        state: 'TX',
    },
    {
        city: 'San Diego',
        url: 'san-diego-ca',
        state: 'CA',
    },
    {
        city: 'San Francisco',
        url: 'san-francisco-ca',
        state: 'CA',
    },
    {
        city: 'Seattle',
        url: 'seattle-wa',
        state: 'WA',
    },
    {
        city: 'Tampa',
        url: 'tampa-fl',
        state: 'FL',
    },
    {
        city: 'Washington',
        url: 'washington-dc',
        state: 'DC',
    },
    {
        city: 'Westminster',
        url: 'westminster-md',
        state: 'MD',
    },
];
