import React from 'react';
import bemCn from 'bem-cn';

import './style.scss';

const b = bemCn('snif-section');

const SnifSection = ({ children, className, w75 = false }) => {
    return (
        <section
            className={b
                .mix(className)
                .mix('px-2 px-md-4')
                .mix(w75 && 'w-75 mx-auto')}
        >
            <div className="container position-relative">{children}</div>
        </section>
    );
};

export default SnifSection;
