import React, { useState } from 'react';
import useMobileScreen from '../../hooks/useMobileScreen';
import FooterNew from '../../../../components/footer-new';

const Footer = (props) => {
    const isMobileSSR = useMobileScreen();

    return <FooterNew {...{ ...props, isMobileSSR, useState }} />;
};

export default Footer;
