import { useCallback, useEffect, useState } from 'react';
import { MOBILE_SCREEN } from '../../consts/screen';

export default function useMobileScreen() {
    const [isMobile, setIsMobile] = useState(
        typeof window !== 'undefined' ? window.matchMedia(MOBILE_SCREEN).matches : true
    );

    const handleResize = useCallback((e) => {
        const window = e.currentTarget;
        const newWidth = window.matchMedia(MOBILE_SCREEN).matches;
        if (isMobile !== newWidth) {
            setIsMobile(newWidth);
        }
    });

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return isMobile;
}
